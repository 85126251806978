* {
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html {
  scroll-behavior: smooth;
  font-size: 16px;
}

.symptombox {
  display: none;
}

input[type="checkbox"].symptombox {
  opacity: 0;
}

input:checked {
  color: #1034a6 !important;
}

button:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Anuphan", "Mitr", "Nunito";
}

i.huge.icon,
i.huge.icons {
  line-height: 1;
  vertical-align: middle;
  font-size: 3.5em;
  transition: 0.7s;
  transform: rotate(0deg);
}

i.big.icon,
i.big.icons {
  line-height: 1;
  vertical-align: middle;
  font-size: 3.3em;
  transform: rotate(144deg);
  transition: 0.7s;
}

.App {
  text-align: center;
  text-align: -webkit-center;
  scroll-behavior: smooth;
  -webkit-text-size-adjust: none;
  /* margin: auto; */
  /* max-width: 38rem; */
  /* padding: 1.5rem; */
}

body {
  font-family: "Anuphan";
  line-height: normal;
}

@font-face {
  font-family: "Anuphan";
  src: url("https://cdn.jsdelivr.net/gh/lazywasabi/thai-web-fonts@7/fonts/Anuphan/Anuphan-Thin.woff2")
    format("woff2");
  font-style: normal;
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: "Anuphan";
  src: url("https://cdn.jsdelivr.net/gh/lazywasabi/thai-web-fonts@7/fonts/Anuphan/Anuphan-ExtraLight.woff2")
    format("woff2");
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: "Anuphan";
  src: url("https://cdn.jsdelivr.net/gh/lazywasabi/thai-web-fonts@7/fonts/Anuphan/Anuphan-Light.woff2")
    format("woff2");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Anuphan";
  src: url("https://cdn.jsdelivr.net/gh/lazywasabi/thai-web-fonts@7/fonts/Anuphan/Anuphan-Regular.woff2")
    format("woff2");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Anuphan";
  src: url("https://cdn.jsdelivr.net/gh/lazywasabi/thai-web-fonts@7/fonts/Anuphan/Anuphan-Medium.woff2")
    format("woff2");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Anuphan";
  src: url("https://cdn.jsdelivr.net/gh/lazywasabi/thai-web-fonts@7/fonts/Anuphan/Anuphan-SemiBold.woff2")
    format("woff2");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Anuphan";
  src: url("https://cdn.jsdelivr.net/gh/lazywasabi/thai-web-fonts@7/fonts/Anuphan/Anuphan-Bold.woff2")
    format("woff2");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "SukhumvitSet-Medium";
  font-style: normal;
  src: url("Fonts/SukhumvitSet-Medium.ttf");
}

@font-face {
  font-family: "SukhumvitSet-SemiBold";
  font-style: normal;
  src: url("Fonts/SukhumvitSet-SemiBold.ttf");
}

@font-face {
  font-family: "SukhumvitSet-Bold";
  font-style: normal;
  src: url("Fonts/SukhumvitSet-Bold.ttf");
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("Fonts/Roboto-Regular.ttf");
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("Fonts/Roboto-Medium.ttf");
}

.swiper {
  width: 100%;
  height: 170px;
}
.swiper-wrapper {
  display: flex;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
}

.swiper-pagination .swiper-pagination-bullet-active {
  background-color: #1a59c2 !important;
}

input:focus {
  outline: none;
}
